
@font-face {
    font-family: 'Proxima Nova';
    src: local('Proxima Nova'),
        url("../Fonts/Proxima-Nova-Italic-400.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: local('Proxima Nova Bold'),
        url("../Fonts/Proxima-Nova-Normal-800.woff") format("woff");
    font-display: swap;

}

* {
    margin: 0;
}

html {
    background-color: "#F5F5F5";
    font-family: 'Proxima Nova'
}

h1 {
    font-family: 'Proxima Nova Bold';
}

